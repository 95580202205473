import {
  FETCH_COMPANIES_SUCCESS,
  FETCH_ALL_COMPANIES_SUCCESS,
  SHOW_COMPANY_MESSAGE,
  HIDE_COMPANY_MESSAGE,
  ON_SHOW_COMPANY_LOADER,
  ON_HIDE_COMPANY_LOADER,
  SWITCH_COMPANY,
  SIGNOUT_USER,
  UPDATE_CURRENT_COMPANY
} from '../ActionTypes';

const INIT_STATE = {
  companies: [],
  allCompanies: [],
  currentCompany: {
    id: null,
    index: 0,
    name: '',
    isOwner: false,
    isFullAccess: false,
    isSecurity: false,
    turnOnDirectoryCode: false,
    pstnRollOverAfterMobileApp: false,
  },
  loader: false,
  alertMessage: '',
  showMessage: false
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_COMPANIES_SUCCESS: {
      return {
        ...state,
        loader: false,
        companies: action.payload.companies,
      };
    }
    case FETCH_ALL_COMPANIES_SUCCESS: {
      let activeCompany = {};
      let index = 0;
      let isNeedToCreateNewCompany = true;

      if (action.payload.companyIdFromUrl) {
        [activeCompany] = action.payload.allCompanies.filter(company => company.id === +action.payload.companyIdFromUrl);
        index = action.payload.allCompanies.indexOf(activeCompany);
        isNeedToCreateNewCompany = false;
      } else if (action.payload.currentCompanyID) {
        const [filteredCompany] = action.payload.allCompanies.filter(company => company.id === +action.payload.currentCompanyID);
        if (filteredCompany) {
          activeCompany = filteredCompany;
          index = action.payload.allCompanies.indexOf(activeCompany);
          isNeedToCreateNewCompany = false;
        }
      }

      if (isNeedToCreateNewCompany) {
        activeCompany.id = action.payload.allCompanies[0].id;
        activeCompany.name = action.payload.allCompanies[0].name;
        activeCompany.is_owner = action.payload.allCompanies[0].is_owner;
        activeCompany.notifications = action.payload.allCompanies[0].notifications;
        activeCompany.subscription_type = action.payload.allCompanies[0].subscription_type;
        activeCompany.is_full_access = action.payload.allCompanies[0].is_full_access;
        activeCompany.is_security = action.payload.allCompanies[0].is_security;
        activeCompany.functionality_packages = action.payload.allCompanies[0].functionality_packages;
        activeCompany.turn_on_directory_code = action.payload.allCompanies[0].turn_on_directory_code;
        activeCompany.pstn_roll_over_after_mobile_application = action.payload.allCompanies[0].pstn_roll_over_after_mobile_application;
        activeCompany.directoryCodeLength = action.payload.allCompanies[0].directory_code_length;
      }

      return {
        ...state,
        loader: false,
        allCompanies: action.payload.allCompanies,
        currentCompany: {
          id: activeCompany.id,
          name: activeCompany.name,
          index,
          isOwner: activeCompany.is_owner,
          notifications: activeCompany.notifications,
          subscriptionType: activeCompany.subscription_type || {},
          functionalityPackages: activeCompany.functionality_packages || {},
          isFullAccess: activeCompany.is_full_access,
          isSecurity: activeCompany.is_security,
          turnOnDirectoryCode: activeCompany.turn_on_directory_code,
          pstnRollOverAfterMobileApp: activeCompany.pstn_roll_over_after_mobile_application,
          directoryCodeLength: activeCompany.directory_code_length,
        }
      };
    }
    case SHOW_COMPANY_MESSAGE: {
      return {
        ...state,
        alertMessage: action.payload,
        showMessage: true,
        loader: false
      };
    }
    case HIDE_COMPANY_MESSAGE: {
      return {
        ...state,
        alertMessage: '',
        showMessage: false,
        loader: false
      };
    }
    case ON_SHOW_COMPANY_LOADER: {
      return {
        ...state,
        loader: true
      };
    }
    case ON_HIDE_COMPANY_LOADER: {
      return {
        ...state,
        loader: false
      };
    }
    case SWITCH_COMPANY: {
      return {
        ...state,
        currentCompany: action.payload
      };
    }
    case UPDATE_CURRENT_COMPANY: {
      return {
        ...state,
        currentCompany: {
          ...state.currentCompany,
        },
        companies: state.companies.map((item, index) => {
          if (index === state.currentCompany.index) {
            return action.payload;
          }
          return item;
        })
      };
    }
    case SIGNOUT_USER: {
      return {
        ...state,
        ...INIT_STATE
      };
    }
    default:
      return state;
  }
};
