import React, { Component } from 'react';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';
import { Redirect, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { IntlProvider } from 'react-intl';
import { NotificationContainer } from 'react-notifications';
import moment from 'moment';
import 'assets/vendors/style';
import MainApp from 'app/index';
import RTL from 'util/RTL';
import asyncComponent from 'util/asyncComponent';
import defaultTheme from './themes/defaultTheme';
import AppLocale from '../lngProvider';
import SignIn from './SignIn';
import { setInitUrl } from '../store/actions/Auth';
import ForgotPassword from './ForgotPassword';
import ForgotPasswordDone from './ForgotPasswordDone';
import PasswordRecovery from './PasswordRecovery/PasswordRecovery.js';
import PasswordSetup from './PasswordRecovery/PasswordSetup.js';
import EmailConfimation from './EmailConfirmation/EmailConfimation.js';
import ResetPasswordDone from './ResetPasswordDone';

import 'moment/locale/es';
import 'moment/locale/fr';

const RestrictedRoute = ({component: Component, authUser, ...rest}) => (
  <Route
    {...rest}
    render={props => (authUser
      ? <Component {...props} />
      : (
        <Redirect
          to={{
            pathname: '/signin',
            state: {from: props.location}
          }}
        />
      ))}
  />
);

class App extends Component {

  componentWillMount() {
    // eslint-disable-next-line no-underscore-dangle
    window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;
    if (this.props.initURL === ''
      && !(this.props.history.location.pathname.indexOf('password/recovery') > -1)) {
      this.props.setInitUrl(this.props.history.location.pathname);
    }
    moment.locale(this.props.locale);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.locale !== this.props.locale) {
      moment.locale(this.props.locale);
    }
  }

  render() {
    const {
      match, location, locale, authUser, initURL, isDirectionRTL
    } = this.props;
    if (location.pathname === '/') {
      if (authUser === null) {
        return (<Redirect to="/signin" />);
      } if (initURL === '' || initURL === '/' || initURL === '/signin') {
        return (<Redirect to="/app" />);
      }
      return (<Redirect to={initURL} />);

    }
    const applyTheme = createMuiTheme(defaultTheme);

    if (isDirectionRTL) {
      applyTheme.direction = 'rtl';
      document.body.classList.add('rtl');
    } else {
      document.body.classList.remove('rtl');
      applyTheme.direction = 'ltr';
    }

    const currentAppLocale = AppLocale[locale];

    return (
      <MuiThemeProvider theme={applyTheme}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <IntlProvider
            locale={currentAppLocale.locale}
            messages={currentAppLocale.messages}>
            <RTL>
              <div className="app-main">
                <Switch>
                  <RestrictedRoute
                    path={`${match.url}app`}
                    authUser={authUser}
                    component={MainApp} />
                  <Route path="/signin" component={SignIn} />
                  <Route path="/forgot_password" component={ForgotPassword} />
                  <Route path="/forgot_password_done" component={ForgotPasswordDone} />
                  <Route path="/password/recovery/:token" component={PasswordRecovery} />
                  <Route path="/admin/password/setup/:uid/:token" component={PasswordSetup} />
                  <Route path="/confirm-email/:uid/:token" component={EmailConfimation} />
                  <Route path="/reset/done/" render={props => <ResetPasswordDone passwordType="reset" {...props} />} />
                  <Route path="/setup/done/" render={props => <ResetPasswordDone passwordType="setup" {...props} />} />
                  <Route
                    component={asyncComponent(() => import('components/Error404'))} />
                </Switch>
                <NotificationContainer />
              </div>
            </RTL>
          </IntlProvider>
        </MuiPickersUtilsProvider>
      </MuiThemeProvider>
    );
  }
}

const mapStateToProps = ({settings, auth}) => {
  const {sideNavColor} = settings;
  const {
    authUser, initURL, locale, isDirectionRTL
  } = auth;
  return {
    sideNavColor, locale, isDirectionRTL, authUser, initURL
  };
};

export default connect(mapStateToProps, {setInitUrl})(App);
