import React from 'react';
import {expireToken} from 'services/apiRoutes';

export default function ExpireToken() {

  const hostname = window.location.hostname;

  const btnExpToken = {
    marginLeft: 'auto',
    
  };


  if (hostname === 'localhost' || hostname === 'company.sandbox.lobibox.com' || hostname === 'company.stage.lobibox.com') {
    return (
      <>
        <button style={btnExpToken} type="button" onClick={expireToken}>Expire Token</button>
      </>
    );
  }

  return null;
}
