const generateCompanyObject = (allCompanies, companyIndex) => ({
  id: allCompanies[companyIndex].id,
  name: allCompanies[companyIndex].name,
  index: companyIndex,
  isOwner: allCompanies[companyIndex].is_owner,
  notifications: allCompanies[companyIndex].notifications,
  subscriptionType: allCompanies[companyIndex].subscription_type,
  functionalityPackages: allCompanies[companyIndex].functionality_packages,
  isFullAccess: allCompanies[companyIndex].is_full_access,
  isSecurity: allCompanies[companyIndex].is_security,
  turnOnDirectoryCode: allCompanies[companyIndex].turn_on_directory_code,
  directoryCodeLength: allCompanies[companyIndex].directory_code_length,
  pstnRollOverAfterMobileApp: allCompanies[companyIndex].pstn_roll_over_after_mobile_application,
});

export default generateCompanyObject;
