// Customizer const
export const TOGGLE_COLLAPSED_NAV = 'toggle_collapse_menu';
export const DRAWER_TYPE = 'drawer_type';
export const FIXED_DRAWER = 'fixed_drawer';
export const COLLAPSED_DRAWER = 'collapsible';
export const MINI_DRAWER = 'mini_drawer';
export const THEME_COLOR = 'theme_color';
export const DARK_THEME = 'dark_theme';
export const WINDOW_WIDTH = 'window-width';
export const CHANGE_DIRECTION = 'change-direction';

export const CHANGE_NAVIGATION_STYLE = 'change-navigation-style';
export const HORIZONTAL_NAVIGATION = 'horizontal_navigation';
export const VERTICAL_NAVIGATION = 'vertical_navigation';

export const HORIZONTAL_MENU_POSITION = 'horizontal_menu_position';
export const ABOVE_THE_HEADER = 'above_the_header';
export const INSIDE_THE_HEADER = 'inside_the_header';
export const BELOW_THE_HEADER = 'below_the_header';

// Auth const
export const SIGNUP_USER = 'signup_user';
export const SIGNUP_USER_SUCCESS = 'signup_user_success';
export const SIGNIN_USER = 'signin_user';
export const SIGNIN_USER_SUCCESS = 'signin_user_success';
export const SIGNOUT_USER = 'signout_user';
export const INIT_URL = 'init_url';
export const RESET_PASSWORD = 'reset_password';
export const GOT_RESET_PASSWORD_MESSAGE = 'got_reset_password_message';
export const PASSWORD_CONFIRMATION = 'password_confirmation';
export const PASSWORD_SETUP = 'password_setup';
export const RESET_PASSWORD_SUCCESS = 'reset_password_success';
export const FETCH_USER_INFO = 'fetch_user_info';
export const FETCH_USER_INFO_SUCCESS = 'fetch_user_info_success';
export const UPDATE_USER_INFO = 'update_user_info';
export const UPDATE_USER_INFO_SUCCESS = 'update_user_info_success';
export const SWITCH_LANGUAGE = 'switch-language';

// Common Module const
export const ON_SHOW_LOADER = 'on_show_loader';
export const ON_HIDE_LOADER = 'on_hide_loader';
export const SHOW_MESSAGE = 'show_message';
export const HIDE_MESSAGE = 'hide_message';

// // Companies Module const

export const FETCH_COMPANIES = 'fetch_companies';
export const FETCH_ALL_COMPANIES = 'fetch_all_companies';
export const FETCH_COMPANY = 'fetch_company';
export const FETCH_COMPANIES_SUCCESS = 'fetch_companies_success';
export const FETCH_ALL_COMPANIES_SUCCESS = 'fetch_all_companies_success';
export const SHOW_COMPANY_MESSAGE = 'show_company_message';
export const HIDE_COMPANY_MESSAGE = 'hide_company_message';
export const ON_SHOW_COMPANY_LOADER = 'show_company_loader';
export const ON_HIDE_COMPANY_LOADER = 'hide_company_loader';
export const SWITCH_COMPANY = 'switch_company';
export const UPDATE_CURRENT_COMPANY = 'update_current_company';

// Integrations
export const FETCH_ALL_INTEGRATIONS = 'fetch_all_integrations';
export const FETCH_ALL_INTEGRATIONS_SUCCESS = 'fetch_all_integrations_success';
export const UPDATE_INTEGRATIONS_LIST = 'update_integrations_list';
export const UPDATE_INTEGRATION_CONFIGURATIONS = 'update_integrations_settings';
export const RESET_INTEGRATIONS_DATA = 'reset_integrations_data';
