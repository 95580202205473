import moment from 'moment';
import mtz from 'moment-timezone';

import { UNKNOWN_SERVER_ERROR_MESSAGE } from 'constants/messages';

export const composeErrorMessage = (messageObject) => {
  const result = [];

  if (typeof messageObject !== 'object') {
    return [UNKNOWN_SERVER_ERROR_MESSAGE];
  }
  const objKeysArray = Object.keys(messageObject);

  objKeysArray.forEach((item) => {
    messageObject[item].forEach((message, i) => {
      result.push(`${item} : ${messageObject[item][i]}`);
    });
  });
  return result;
};

export const composeTimestamp = timestamp => moment(timestamp).utc(0).format('LLL');

export const composeTimestampWithLocalUTC = timestamp => mtz(timestamp).utc(0).format('LLL');

export const composeVuFullName = vu => `${vu.name} (${vu.building}, ${vu.company})`;

export const composeTenantFullName = (tenant) => {
  if (!tenant) {
    return '-';
  }
  const firstName = tenant.first_name || '';
  const lastName = tenant.last_name || '';
  return `${firstName} ${lastName}`;
};

export const composeSecurity = (security) => {
  if (!security) {
    return '-';
  }
  return security;
};

export const composeVuForLookup = (availableVu) => {
  const filterData = availableVu.map(vu => ({ value: vu.id, label: vu.name }));
  return filterData;
};

export const composeCompanySecurityAdminForLookup = (admins) => {
  const securityForLookUp = [];
  if (!admins) return null;

  admins.forEach((item) => {

    if (!item) return;

    if (item === 'security_empty') {
      securityForLookUp.push({ value: item, label: 'Without security' });
      return;
    }
    if (item === 'only_security') {
      securityForLookUp.push({ value: item, label: 'All with security names' });
      return;
    }

    if (item.includes('+')) {
      item.split('+').join('%2B');
    }
    securityForLookUp[item] = item;
    securityForLookUp.push({ value: item, label: item });
  });
  return securityForLookUp;
};

export const composeTenantsForLookup = (availableTenants) => {
  const defaultItems = [{
    value: 'members_empty',
    label: 'Without member',
  },
  {
    value: 'only_members',
    label: 'All with members names',
  }];

  const filterData = availableTenants.map(tenant => ({ value: tenant.id, label: `${tenant.first_name} ${tenant.last_name}` }));
  return [...filterData, ...defaultItems];
};

export const composeEventsForLookup = (events) => {
  const eventsIds = Object.keys(events);

  return eventsIds.map(id => ({ value: id, label: events[id] }));
};

export const downloadFile = (data, fileName) => {
  const url = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const composeEventsChain = events => events.map((evt, i) => ((events.length === i + 1) ? evt.type : `${evt.type} - `));

export const removePlusFromDialCode = dialCode => (dialCode.includes('+') ? dialCode.slice(1) : dialCode);
